/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'

import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../../../components/Button'
import { Flex } from '../../../components/layout/Flex'
import { Page } from '../../../components/leagueQuiz/Page'
import { QuizScoreComponent } from '../../../components/leagueQuiz/QuizScoreComponent'
import { Text } from '../../../components/Text'
import {
  AD_CAMPAIGN_DISPLAY_TIME_MS,
  AD_CAMPAIGN_HEADER_COLLAPSE_WHEN_TIME_LEFT_MS,
} from '../../../constants'
import { useT } from '../../../lib/i18n/useT'
import { fadeIn, growAndFade } from '../../../styles/animations'
import { margin, Spacer } from '../../../styles/margin'
import { colors } from '../../../styles/theme'
import { useInterval } from '../../../utils/useInterval'
import { titleFromQuiz } from '../../../utils/util'
import { EnablePushNotificationsBanner } from '../../Home/EnablePushNotificationsBanner'
import { EnableNotifications } from '../../LeagueQuiz/EnableNotifications'
import { useLeagueQuizContext } from '../../LeagueQuiz/LeagueQuizContext'
import {
  LiveQuizInstance,
  useLiveUserResultQuery,
} from '../../LiveQuiz/queries'

export const LiveFinished: FC<{
  instance: LiveQuizInstance
  playAnimation?: boolean
}> = ({ instance, playAnimation = false }) => {
  const league = instance.liveHostLeague
  const t = useT()
  const initialRemaining = !playAnimation ? 0 : AD_CAMPAIGN_DISPLAY_TIME_MS

  const [remaining, setRemaining] = useState(initialRemaining)
  const [timerRunning] = useState(playAnimation)
  const {
    setIsExpandedAdActive,
    isExpandedAdActive,
    hasSanityCampaign,
    videoAdActive,
  } = useLeagueQuizContext()

  const navigate = useNavigate()

  const viewerResult = useLiveUserResultQuery(instance.id)

  const result = viewerResult.data?.getLivePlayQuizInstance.result

  useInterval(
    () => {
      setRemaining((remaining) => remaining - 100) // decrease by 10 every 10 milliseconds
    },
    remaining <= 0 || !timerRunning ? null : 100 // run every 10 milliseconds
  )

  useEffect(() => {
    if (playAnimation) {
      setIsExpandedAdActive(true)
    }
  }, [])

  useEffect(() => {
    if (
      hasSanityCampaign &&
      remaining <= AD_CAMPAIGN_HEADER_COLLAPSE_WHEN_TIME_LEFT_MS
    ) {
      setIsExpandedAdActive(false)
    }
  }, [remaining])

  const textColor = colors.black

  return (
    <Page showFcQuizLogo logoColor={colors.grey200}>
      <Flex
        column
        horizontal={'center'}
        vertical={'space-between'}
        css={css`
          height: 100%;
          padding-top: 16px;
          padding-bottom: 16px;
          margin: 0 auto;
          width: 100%;
        `}
      >
        <Flex
          column
          horizontal={'center'}
          gap={'medium'}
          css={css`
            width: 100%;
          `}
        >
          {(!isExpandedAdActive || !hasSanityCampaign) && (
            <Text color={colors.grey400} size={'small'}>
              {titleFromQuiz(instance.leagueQuiz, t)}
            </Text>
          )}
          {result && (
            <QuizScoreComponent
              questionsCount={instance.leagueQuiz.questionsCount}
              result={result}
              remaining={remaining}
              totalCalculationTime={initialRemaining}
              playAnimation={playAnimation}
            />
          )}
        </Flex>
        {!videoAdActive && remaining <= 2000 && (
          <Flex
            column
            vertical={'center'}
            css={[
              margin.top('large'),
              css`
                opacity: 0;
                animation: ${playAnimation ? growAndFade : fadeIn} 350ms
                  cubic-bezier(0.4, 0, 0.15, 1);
                animation-delay: 600ms;
                animation-fill-mode: forwards;
                width: 100%;
              `,
            ]}
          >
            <Spacer height={24} />
            {league && (
              <PrimaryButton
                onClick={() => {
                  //was challenge-league
                  navigate(`/league/${league.slug}`, {
                    replace: true,
                  })
                }}
                css={[
                  margin.top('large'),
                  css`
                    height: 50px;
                  `,
                ]}
              >
                {t('To the league page')}
              </PrimaryButton>
            )}
            {!league && (
              <PrimaryButton
                onClick={() => {
                  navigate(`/series/${instance.leagueQuiz.league.id}`, {
                    replace: true,
                  })
                }}
                css={[
                  margin.top('large'),
                  css`
                    height: 50px;
                  `,
                ]}
              >
                {t('To the series page')}
              </PrimaryButton>
            )}
            <EnableNotifications textColor={textColor} />
            <EnablePushNotificationsBanner css={margin.top('large')} />
          </Flex>
        )}
      </Flex>
    </Page>
  )
}
