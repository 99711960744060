import { gql, useMutation, useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { LocaleString } from '../../lib/i18n/locale'
import { MaskShape } from '../../lib/imageMask'
import { ClubRole } from '../Clubs/queries'
import {
  League,
  LeagueFrequency,
  RewardCriteria,
  RewardFrequency,
} from '../League/queries'

import { User } from '../Profile/queries'
import { AnalyticsFilter } from './AdminAnalyticsPage'

export const useSendNewsletterMutation = () =>
  useMutation<
    { sendNewsletter: boolean },
    { input: { subject: LocaleString; content: LocaleString } }
  >(gql`
    mutation sendNewsletter($input: SendNewsletterInput!) {
      sendNewsletter(input: $input)
    }
  `)

export const useSendPushNotificationsMutation = () =>
  useMutation<
    { sendPushNotifications: boolean },
    { input: { title: LocaleString; body: LocaleString; url?: string } }
  >(gql`
    mutation sendPushNotifications($input: SendPushNotificationsInput!) {
      sendPushNotifications(input: $input)
    }
  `)

export type AdminPageUser = {
  id: string
  createdAt: string
  username: string
  slug: string
  email: string
  pushNotificationToken?: string
  isAdmin: boolean
  avatarData?: string
  locale?: string
  displayName?: string
  isTranslater?: boolean
}

export type LocaleCount = {
  locale: string
  count: number
}

export const useUsersQuery = () => {
  return useQuery<{ users: AdminPageUser[] }>(gql`
    query users {
      users {
        id
        slug
        createdAt
        username
        displayName
        email
        pushNotificationToken
        isAdmin
        avatarData
        displayName
        locale
        isTranslater
      }
    }
  `)
}

const usersSearchPaginatedQuery = gql`
  query usersSearchPaginated($page: Int!, $pageSize: Int!, $query: String!) {
    usersSearchPaginated(page: $page, pageSize: $pageSize, query: $query) {
      id
      slug
      createdAt
      username
      displayName
      email
      pushNotificationToken
      isAdmin
      avatarData
      displayName
      locale
      isTranslater
    }
  }
`

export const useUsersSearchPaginatedQuery = (
  page: number,
  pageSize: number,
  query: string
) => {
  return useQuery<{ usersSearchPaginated: AdminPageUser[] }>(
    usersSearchPaginatedQuery,
    {
      variables: {
        page,
        pageSize,
        query,
      },
      skip: query.trim().length < 3,
    }
  )
}

export type ParticipantItem = {
  user: {
    id: string
    username: string | null
    displayName: string | null
    slug: string
    avatarData?: string
    email: string
    mainClubMembership?: {
      id: string
      role: ClubRole
      club: {
        id: string
        name: string
        logoUrl?: string
        logoShape: MaskShape
      }
    }
  }
  score: number
}

export type WinnerItem = {
  id: string
  user: AdminPageUser
  wonDate: Date
}

export type EligibleParticipantsQueryResult = {
  leagueUsersEligibleForPrize: ParticipantItem[]
}

export const participantFragment = gql`
  fragment ParticipantItem on ParticipantItem {
    user {
      id
      username
      slug
      avatarData
      email
      displayName
      mainClubMembership {
        id
        role
        club {
          id
          name
          logoUrl
          logoShape
        }
      }
    }
    score
  }
`

export const winnerFragment = gql`
  fragment WinnerItem on WinnerItem {
    id
    user {
      id
      username
      email
      pushNotificationToken
      isAdmin
      avatarData
      displayName
      isTranlater
    }
    wonDate
  }
`

export const leagueFragment = gql`
  fragment League on League {
    id
    title
    slug
    imageUrl
    description
    isRewarded
    reward {
      rewardDescription
      rewardImageUrl
      numberOfWinners
      rewardFrequency
      rewardCriteria {
        minScore
        hasPlayedAllQuizzesInPeriod
      }
    }
  }
`

export const eligibleParticipantsQuery = gql`
  query League(
    $slug: String!
    $minScore: Int!
    $startDate: String
    $endDate: String
  ) {
    leagueUsersEligibleForPrize(
      slug: $slug
      minScore: $minScore
      startDate: $startDate
      endDate: $endDate
    ) {
      ...ParticipantItem
    }
  }
  ${participantFragment}
`

export const addWinnerToLeagueRewardMutation = gql`
  mutation addWinnerToLeagueReward(
    $leagueId: String!
    $userId: String!
    $wonDate: String!
  ) {
    addWinnerToLeagueReward(
      leagueId: $leagueId
      userId: $userId
      wonDate: $wonDate
    ) {
      ...League
    }
  }
  ${leagueFragment}
`

export const leagueRewardWinnersQuery = gql`
  query leagueRewardWinners($leagueId: String!) {
    leagueRewardWinners(leagueId: $leagueId) {
      ...WinnerItem
    }
  }
  ${winnerFragment}
`

export const useLeagueParticipantsQuery = (
  slug: string,
  minScore: number,
  startDate: Date | undefined,
  endDate: Date | undefined
) => {
  return useQuery<
    EligibleParticipantsQueryResult,
    {
      slug: string
      minScore: number
      startDate: Date | undefined
      endDate: Date | undefined
    }
  >(eligibleParticipantsQuery, {
    variables: {
      slug,
      minScore,
      startDate,
      endDate,
    },
  })
}

export type AdminPageLeague = {
  id: string
  title: string
  slug: string
  imageUrl: string
  description: string
  availableFrom: Date
  availableTo: Date
  isRewarded: boolean
  frequency: LeagueFrequency
  reward: {
    rewardDescription: string
    rewardImageUrl: string
    numberOfWinners: number
    rewardFrequency: RewardFrequency
    rewardCriteria: RewardCriteria
  }
}

export const useLeagueQuery = () => {
  return useQuery<{ leagues: AdminPageLeague[] }>(gql`
    query leagues {
      leagues {
        id
        title
        slug
        imageUrl
        description
        availableFrom
        availableTo
        isRewarded
        frequency
        reward {
          rewardDescription
          rewardImageUrl
          numberOfWinners
          rewardFrequency
          rewardCriteria {
            minScore
            hasPlayedAllQuizzesInPeriod
          }
        }
      }
    }
  `)
}

export const useLeagueRewardWinnersQuery = (
  leagueId: string,
  isRewarded: boolean
) => {
  if (!isRewarded) return { data: null, loading: null }
  return useQuery<{ leagueRewardWinners: WinnerItem[] }>(
    leagueRewardWinnersQuery,
    {
      variables: {
        leagueId,
      },
    }
  )
}

export const useAddWinnerToLeagueReward = (
  leagueId: string,
  userId: string,
  wonDate: Date
) =>
  useMutation<
    League,
    {
      leagueId: string
      userId: string
      wonDate: Date
    }
  >(addWinnerToLeagueRewardMutation, {
    variables: {
      leagueId,
      userId,
      wonDate,
    },
  })

export const useSyncTimelessQuestionsToDatabase = () => {
  return useMutation<{ syncTimelessQuestionsToDatabase: number }>(gql`
    mutation syncTimelessQuestionsToDatabase {
      syncTimelessQuestionsToDatabase
    }
  `)
}

export type DbQuizCategoryWithCount = {
  id: string
  content: string
  count: number
}

export type AdminPageDbQuestion = {
  id: string
  locale: string
  source: string
  answered_times: number
  difficulty?: number
  content: string
}

export const useCategoriesWithQuestionCountQuery = ({
  questionLocale = 'no',
}: {
  questionLocale?: string
}) => {
  return useQuery<{ categoriesWithQuestionCount: DbQuizCategoryWithCount[] }>(
    gql`
      query categoriesWithQuestionCount($questionLocale: String!) {
        categoriesWithQuestionCount(questionLocale: $questionLocale) {
          id
          content
          count
        }
      }
    `,
    {
      variables: {
        questionLocale,
      },
      fetchPolicy: 'cache-and-network',
    }
  )
}

export const useQuestionsInCategoryQuery = ({
  categoryId,
  questionLocale = 'no',
}: {
  categoryId?: string
  questionLocale?: string
}) => {
  return useQuery<{ questionsInCategory: AdminPageDbQuestion[] }>(
    gql`
      query questionsInCategory($categoryId: String, $questionLocale: String!) {
        questionsInCategory(
          categoryId: $categoryId
          questionLocale: $questionLocale
        ) {
          id
          locale
          source
          answered_times
          difficulty
          content
        }
      }
    `,
    {
      variables: {
        categoryId,
        questionLocale,
      },
      fetchPolicy: 'cache-and-network',
    }
  )
}

export type AdminPageClub = {
  id: string
  name: string
  slug: string
  description: string
  logoUrl?: string
  backgroundImageUrl?: string
  logoShape: MaskShape
  public: boolean
  verified: boolean
  membersCount: number
  externalSiteUrl?: string
  leagueSubscriptions: {
    id: string
    clubId: string
    leagueId: string
    leagueTitle?: string
    showVideoAd: boolean
  }[]
}

export const AdminPageClubFragment = gql`
  fragment AdminPageClub on Club {
    id
    name
    slug
    description
    logoUrl
    backgroundImageUrl
    logoShape
    public
    verified
    membersCount
    externalSiteUrl
    leagueSubscriptions {
      id
      clubId
      leagueId
      leagueTitle
      showVideoAd
    }
  }
`

export const useAdminClubsQuery = () => {
  return useQuery<{ clubs: AdminPageClub[] }>(gql`
    query clubs {
      clubs {
        ...AdminPageClub
      }
    }
    ${AdminPageClubFragment}
  `)
}

export const useSetClubExternalSiteUrlMutation = () => {
  return useMutation<
    { setClubExternalSiteUrl: { id: string } },
    { input: { clubIdOrSlug: string; externalSiteUrl: string } }
  >(gql`
    mutation setClubExternalSiteUrl($input: SetClubExternalSiteUrlInput!) {
      setClubExternalSiteUrl(input: $input) {
        id
      }
    }
  `)
}

export const useSubscribeClubtoLeagueMutation = () => {
  return useMutation<
    unknown,
    { clubIdOrSlug: string; leagueId: string; isOwner: boolean }
  >(gql`
    mutation subscribeClubToLeague(
      $clubIdOrSlug: String!
      $leagueId: String!
      $isOwner: Boolean!
    ) {
      subscribeClubToLeague(
        clubIdOrSlug: $clubIdOrSlug
        leagueId: $leagueId
        isOwner: $isOwner
      ) {
        id
      }
    }
  `)
}

export const useUnsubscribeClubFromLeagueMutation = () => {
  return useMutation<unknown, { clubIdOrSlug: string; leagueId: string }>(gql`
    mutation unsubscribeClubFromLeague(
      $clubIdOrSlug: String!
      $leagueId: String!
    ) {
      unsubscribeClubFromLeague(
        clubIdOrSlug: $clubIdOrSlug
        leagueId: $leagueId
      ) {
        id
      }
    }
  `)
}

export const useToggleClubSubShowVideoAdMutation = () => {
  return useMutation<
    AdminPageClub,
    { input: { clubId: string; clubLeagueSubscriptionId: string } }
  >(gql`
    mutation toggleClubSubShowVideoAd($input: ToggleClubSubShowVideoAdInput!) {
      toggleClubSubShowVideoAd(input: $input) {
        ...AdminPageClub
      }
    }
    ${AdminPageClubFragment}
  `)
}
// localeCountQuery

const localeCountQuery = gql`
  query localeCountQuery {
    localeCountQuery {
      locale
      count
    }
  }
`

export const useLocaleCountQuery = () => {
  return useQuery<{ localeCountQuery: LocaleCount[] }>(localeCountQuery, {
    fetchPolicy: 'cache-and-network',
  })
}

export type AdminDeleteUserDataInput = {
  userId: string
  onlyDeleteData: boolean
}

export const useAdminDeleteUserMutation = () => {
  return useMutation<
    { adminDeleteUser: boolean },
    { input: AdminDeleteUserDataInput }
  >(gql`
    mutation adminDeleteUser($input: AdminDeleteUserDataInput!) {
      adminDeleteUser(input: $input)
    }
  `)
}

export const useFcEventTypesQuery = () => {
  return useQuery<{ getEventTypes: string[] }>(gql`
    query getEventTypes {
      getEventTypes
    }
  `)
}

//     getEvents(input: GetEventsInput): [Event!]!

export type GetEventsInput = {
  eventType: string | undefined
  startDate: string
  endDate: string
  groupBy?: string
  filterBy?: AnalyticsFilter[]
}

export const getEventsQuery = gql`
  query getEvents($input: GetEventsInput!) {
    getEvents(input: $input) {
      id
      eventType
      eventData
      userId
      createdAt
    }
  }
`

export type FcEventRaw = {
  id: string
  eventType: string
  eventData: string
  userId: string
  createdAt: string
}

export type FcEvent = {
  id: string
  eventType: string
  eventData: {
    clubId?: string
    leagueId?: string
    quizId?: string
    clubLeagueSubscriptionId?: string
    campaignId?: string
  }
  userId: string
  createdAt: string
}

export type FcEventsDaily = {
  day: Date
  events: FcEvent[]
}

export const useGetEventsQuery = (input: GetEventsInput) => {
  const query = useQuery<{ getEvents: FcEventRaw[] }>(getEventsQuery, {
    variables: {
      input,
    },
    skip: !input.eventType,
  })

  const data = useMemo(() => {
    if (!query.data?.getEvents) {
      return []
    }

    const events = query.data.getEvents.map((event) => {
      const eventData = JSON.parse(event.eventData)
      return {
        ...event,
        eventData,
      }
    })

    const eventsByDay: FcEventsDaily[] = []
    events.forEach((event) => {
      const eventDayNoTime = new Date(event.createdAt)
      const eventDay = new Date(
        eventDayNoTime.getFullYear(),
        eventDayNoTime.getMonth(),
        eventDayNoTime.getDate()
      )

      const dayIndex = eventsByDay.findIndex(
        (day) => day.day.getTime() === eventDay.getTime()
      )
      if (eventsByDay.length === 0) {
        eventsByDay.push({
          day: eventDay,
          events: [event],
        })
      } else if (dayIndex === -1) {
        eventsByDay.push({
          day: eventDay,
          events: [event],
        })
      } else {
        eventsByDay[dayIndex].events.push(event)
      }
    })

    eventsByDay.sort((a, b) => a.day.getTime() - b.day.getTime())

    return eventsByDay
  }, [query.data?.getEvents])

  return {
    ...query,
    data,
  }
}

export const getEventsQueryV2 = gql`
  query getEventsV2($input: GetEventsInput!) {
    getEventsV2(input: $input) {
      label
      data
      total
    }
  }
`

export type EventDataset = {
  label: string
  data: (number | undefined)[]
  total: number
}

export const useGetEventsQueryV2 = (input: GetEventsInput) => {
  const query = useQuery<{ getEventsV2: EventDataset[] }>(getEventsQueryV2, {
    variables: {
      input,
    },
    skip: !input.eventType,
  })

  const data = useMemo(() => {
    if (!query.data?.getEventsV2) {
      return []
    }

    const events = [...query.data.getEventsV2].sort((a, b) => b.total - a.total)

    return events
  }, [query.data?.getEventsV2])

  return { ...query, data }
}

//     usersWithPushNotificationTokens(locales: [Locale!]!): Int!

export const usersWithPushNotificationTokensQuery = gql`
  query usersWithPushNotificationTokens($locales: [String!]!) {
    usersWithPushNotificationTokens(locales: $locales)
  }
`

export const useUsersWithPushNotificationTokensQuery = (locales: string[]) => {
  return useQuery<{ usersWithPushNotificationTokens: number }>(
    usersWithPushNotificationTokensQuery,
    {
      variables: {
        locales,
      },
    }
  )
}

// Authentic data sync

interface SyncResponse {
  message: string
  addedObjects: number
}

// Leagues

export const useAuthenticLeagueSyncMutation = () => {
  return useMutation<{ addLeagues: SyncResponse }>(gql`
    mutation addLeagues {
      addLeagues {
        message
        addedObjects
      }
    }
  `)
}

// Teams

export const useAuthenticTeamSyncMutation = () => {
  return useMutation<{ addTeams: SyncResponse }>(gql`
    mutation addTeams {
      addTeams {
        message
        addedObjects
      }
    }
  `)
}

// Players

export const useAuthenticPlayerSyncMutation = () => {
  return useMutation<
    { addPlayers: SyncResponse },
    {
      leagues: number[]
    }
  >(gql`
    mutation addPlayers($leagues: [Int!]!) {
      addPlayers(leagues: $leagues) {
        message
        addedObjects
      }
    }
  `)
}

// Data sync status
export type DataSyncStatus = {
  type: string
  status: string
  objectsAdded: number
  totalObjects: number
  startedAt: string
  endedAt: string
}

export type FullDataSyncStatus = {
  leagues: DataSyncStatus
  teams: DataSyncStatus
  players: DataSyncStatus
}

export const dataSyncStatusQuery = gql`
  query getDataSyncStatus {
    getDataSyncStatus {
      leagues {
        type
        status
        objectsAdded
        totalObjects
        startedAt
        endedAt
      }
      teams {
        type
        status
        objectsAdded
        totalObjects
        startedAt
        endedAt
      }
      players {
        type
        status
        objectsAdded
        totalObjects
        startedAt
        endedAt
      }
    }
  }
`

export const useDataSyncStatusQuery = () => {
  return useQuery<{ getDataSyncStatus: FullDataSyncStatus }>(
    dataSyncStatusQuery
  )
}

export const loadTestMutation = gql`
  mutation loadTest($input: LoadTestInput!) {
    loadTest(input: $input)
  }
`

export type LoadTestType = 'homepage' | 'leaguepage' | 'livequiz'

export type LoadTestInput = {
  type: LoadTestType
  targetEnvironment: string
}

export const useLoadTestMutation = () => {
  return useMutation<{ loadTest: boolean }, { input: LoadTestInput }>(
    loadTestMutation
  )
}

export type AnalyticsLabel = {
  id: string
  label: string
}

export const analyticsLabelsQuery = gql`
  query getAnalyticsLabels {
    getAnalyticsLabels {
      id
      label
    }
  }
`

export const useAnalyticsLabelsQuery = () => {
  return useQuery<{ getAnalyticsLabels: AnalyticsLabel[] }>(
    analyticsLabelsQuery
  )
}

//    editAnalyticsLabel(input: EditAnalyticsLabelInput!): [AnalyticsLabel!]!

export const useEditAnalyticsLabelMutation = () => {
  return useMutation<
    { editAnalyticsLabel: AnalyticsLabel[] },
    { input: AnalyticsLabel }
  >(
    gql`
      mutation editAnalyticsLabel($input: EditAnalyticsLabelInput!) {
        editAnalyticsLabel(input: $input) {
          id
          label
        }
      }
    `
  )
}

export type CampaignMinimal = {
  id: string
  name: string
  displayName?: string
  campaignLink?: string
  availableInRegions: string[]
  availableFrom: string
  availableTo?: string
  backgroundColor?: string
}

export type Advertiser = {
  id: string
  name: string
  email: string
  logo?: string
  createdAt: string
  updatedAt: string
  campaigns?: CampaignMinimal[]
  members?: User[]
}

export const useCreateAdvertiserMutation = () => {
  return useMutation<
    { createAdvertiser: { id: string } },
    { input: { name: string; email: string; logo?: string } }
  >(gql`
    mutation createAdvertiser($input: CreateAdvertiserInput!) {
      createAdvertiser(input: $input) {
        id
      }
    }
  `)
}

export const useAdvertisersQuery = () => {
  return useQuery<{ advertisers: Advertiser[] }>(gql`
    query advertisers {
      advertisers {
        id
        name
        email
        logo
        createdAt
        updatedAt
        members {
          id
          username
          email
          avatarData
        }
      }
    }
  `)
}

export const useAdvertiserByIdQuery = ({
  advertiserId,
}: {
  advertiserId: string
}) => {
  return useQuery<{ advertiser: Advertiser }, { advertiserId: string }>(
    gql`
      query advertiserQuery($advertiserId: String!) {
        advertiser(advertiserId: $advertiserId) {
          id
          name
          campaigns {
            id
            name
            displayName
            availableInRegions
            campaignLink
            availableFrom
            availableTo
            partnerLogoUrl
            header {
              headerColor
            }
          }
          members {
            id
            username
            email
            avatarData
          }
        }
      }
    `,
    { variables: { advertiserId } }
  )
}

export const useAddAdvertiserMemberMutation = () => {
  return useMutation<
    { addAdvertiserMember: Advertiser },
    { advertiserId: string; email: string }
  >(gql`
    mutation addAdvertiserMember($advertiserId: String!, $email: String!) {
      addAdvertiserMember(advertiserId: $advertiserId, email: $email) {
        id
        name
        email
        logo
        createdAt
        updatedAt
        members {
          id
          username
          email
          avatarData
        }
      }
    }
  `)
}
