/** @jsxImportSource @emotion/react */

import { Flex } from '../../components/layout/Flex'
import { Layout } from '../../components/layout/Layout'
import { PageContent } from '../../components/layout/PageContent'

import { HeadingNew } from '../../components/HeadingNew'
import {
  AdSize,
  RevIqAdComponent,
} from '../../components/reviq/RevIqAdComponent'
import { colors } from '../../styles/theme'
import { useState } from 'react'
import { useInterval } from '../../utils/useInterval'
import { TextNew } from '../../components/TextNew'

export const AdTestPage = () => {
  const [timeUntilVisible, setTimeUntilVisible] = useState(5000)

  useInterval(
    () => {
      setTimeUntilVisible((prev) => prev - 100)
    },
    timeUntilVisible > 0 ? 100 : null
  )

  const visible = timeUntilVisible <= 0

  return (
    <Layout backgroundColor={colors.green600} hideNavbar>
      <Flex column grow>
        <PageContent>
          <HeadingNew color={colors.white} level={1}>
            {'Ad Test'}
          </HeadingNew>

          {!visible && (
            <TextNew color={colors.white}>{`Time until visible: ${
              timeUntilVisible / 1000
            }`}</TextNew>
          )}

          <Flex horizontal="center" vertical="center" column gap={'large'}>
            <RevIqAdComponent
              adSize={AdSize.MEDIUM_RECTANGLE}
              visible={visible}
            />
            <RevIqAdComponent adSize={AdSize.BANNER} visible={visible} />
          </Flex>
        </PageContent>
      </Flex>
    </Layout>
  )
}
