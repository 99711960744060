import { gql, useQuery } from '@apollo/client'
import { LeagueQuizMinimal } from '../League/queries'

import {
  ClubLeagueSubscriptionData,
  PublicClub,
  PublicClubInfo,
  PublicLeague,
} from './types'

const publicClubQuery = gql`
  query publicClub($clubId: ID!) {
    publicClub(id: $clubId) {
      id
      name
      slug
      public
      verified
      logoUrl
      logoShape
      backgroundImageUrl
      externalSiteUrl
      subscribedLeagues {
        id
        title
        participantCount
        frequency
        currentQuiz {
          id
          title
          slug
          imageUrl
          league {
            id
            title
            description
          }
          embedPromotionalText
          availableFrom
          viewerCanStartQuiz
          availableTo
          viewerData {
            id
            hasCompletedQuiz
            result {
              totalScore
              leagueQuizInstanceId
            }
            canStartQuiz
            activeQuizInstance {
              id
            }
          }
        }
      }
    }
  }
`

const publicClubQueryV2 = gql`
  query publicClub($clubId: ID!) {
    publicClub(id: $clubId) {
      id
      logoUrl
    }
  }
`

export const usePublicClubQueryV2 = (clubId: string) => {
  return useQuery<{ publicClub?: PublicClubInfo }, { clubId: string }>(
    publicClubQueryV2,
    {
      variables: { clubId },
      skip: clubId === '' || clubId === null || clubId === undefined,
    }
  )
}

export const usePublicClubQuery = (clubId: string) => {
  return useQuery<{ publicClub?: PublicClub }, { clubId: string }>(
    publicClubQuery,
    {
      variables: { clubId },
      skip: clubId === '' || clubId === null || clubId === undefined,
    }
  )
}

const publicClubLeagueQuery = gql`
  query clubLeagueSubscription($clubLeagueSubscriptionId: ID!) {
    clubLeagueSubscription(
      clubLeagueSubscriptionId: $clubLeagueSubscriptionId
    ) {
      leagueId
      clubId
    }
  }
`
export const usePublicClubLeagueQuery = (clubLeagueSubscriptionId: string) => {
  return useQuery<
    { clubLeagueSubscription?: ClubLeagueSubscriptionData },
    { clubLeagueSubscriptionId: string }
  >(publicClubLeagueQuery, { variables: { clubLeagueSubscriptionId } })
}

const publicLeagueQuery = gql`
  query publicLeague($leagueId: ID!) {
    publicLeague(id: $leagueId) {
      id
      title
      imageUrl
      participantCount
      frequency
      currentQuiz {
        id
        title
        imageUrl
        embedPromotionalText
        availableFrom
        viewerCanStartQuiz
        availableTo
        viewerData {
          id
          hasCompletedQuiz
          result {
            totalScore
            leagueQuizInstanceId
          }
          canStartQuiz
          activeQuizInstance {
            id
          }
        }
        league {
          id
          title
          description
        }
      }
    }
  }
`

export const usePublicLeagueQuery = (leagueId: string) => {
  return useQuery<{ publicLeague?: PublicLeague }, { leagueId: string }>(
    publicLeagueQuery,
    { variables: { leagueId } }
  )
}

const leagueQuizQuery = gql`
  query leagueQuiz($leagueQuizId: ID!) {
    leagueQuiz(id: $leagueQuizId) {
      id
      title
      slug
      imageUrl
      questionsCount
      participantCount
      availableFrom
      availableTo
      status
      viewerCanStartQuiz
      embedPromotionalText
      quizType
      viewerData {
        canStartQuiz
        hasCompletedQuiz
        activeQuizInstance {
          id
        }
        result {
          leagueQuizInstanceId
          correctAnswersCount
          correctAnswerScore
          timeBonus
          totalScore
        }
      }
    }
  }
`

export const useLeagueQuizQuery = (leagueQuizId: string) => {
  return useQuery<{ leagueQuiz?: LeagueQuizMinimal }, { leagueQuizId: string }>(
    leagueQuizQuery,
    { variables: { leagueQuizId } }
  )
}

export const useShowVideoAdForClubLeagueSubscriptionQuery = (
  clubLeagueSubscriptionId: string
) => {
  return useQuery<
    { showVideoAdForClubLeagueSubscription: boolean },
    { clubLeagueSubscriptionId: string }
  >(
    gql`
      query showVideoAdForClubLeagueSubscription(
        $clubLeagueSubscriptionId: ID!
      ) {
        showVideoAdForClubLeagueSubscription(
          clubLeagueSubscriptionId: $clubLeagueSubscriptionId
        )
      }
    `,
    { variables: { clubLeagueSubscriptionId } }
  )
}
