/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import Lottie from 'lottie-react'
import { FC, useEffect, useState } from 'react'
import {
  AD_CAMPAIGN_DISPLAY_HALFTIME,
  AD_CAMPAIGN_HEADER_COLLAPSE_WHEN_TIME_LEFT_MS,
} from '../../constants'
import { useT } from '../../lib/i18n/useT'
import { fadeIn, growAndFade } from '../../styles/animations'
import { margin, Spacer } from '../../styles/margin'
import { colors } from '../../styles/theme'
import { useInterval } from '../../utils/useInterval'
import {
  Badge,
  dailyCareerPathQuery,
  useGetDailyCareerPathQuery,
} from '../../views/League/queries'
import { useLeagueQuizContext } from '../../views/LeagueQuiz/LeagueQuizContext'
import {
  CareerPathQuizInstance,
  useLeagueQuizFinished,
  useStartCareerPathQuizMutation,
} from '../../views/LeagueQuiz/queries'
import { BadgeModalContent } from '../badges/BadgeModalContent'
import { Flex } from '../layout/Flex'
import { Modal, ModalTrigger } from '../modal/Modal'

import { useApolloClient } from '@apollo/client'
import { confetti } from 'react-rewards'
import { useNavigate } from 'react-router-dom'
import { useAuthContext, viewerQuery } from '../../lib/AuthContext'
import { AnalyticsEventType, trackEvent } from '../../lib/analytics'
import { PrimaryButton, SecondaryButton } from '../Button'
import { TextNew } from '../TextNew'
import { MyPlacement } from '../leagueQuiz/MyPlacement'
import { Page } from '../leagueQuiz/Page'
import { TopPlayers } from '../leagueQuiz/TopPlayers'
import { GuessIndicator } from './ActiveCareerPathQuestion'
import CareerPath from './CareerPath'
import { CareerPathQuestionResult } from './LastCompletedCareerPathQuestion'

export const FinishedCareerPathDaily: FC<{
  leagueQuizInstance: CareerPathQuizInstance
  playAnimation?: boolean
  newBadges: Badge[]
}> = ({ leagueQuizInstance, playAnimation = false, newBadges }) => {
  const { authUser } = useAuthContext()
  const t = useT()
  const { setIsExpandedAdActive, hasSanityCampaign, videoAdActive } =
    useLeagueQuizContext()
  const initialRemaining = !playAnimation
    ? 0
    : hasSanityCampaign
    ? AD_CAMPAIGN_DISPLAY_HALFTIME
    : AD_CAMPAIGN_DISPLAY_HALFTIME / 2
  const leagueSlug = 'the-daily-career-path'
  const { data, loading: nextQuizLoading } =
    useGetDailyCareerPathQuery(leagueSlug)

  const lastCompletedQuestion = leagueQuizInstance.completedQuestions[0]

  const [showBadgeModal, setShowBadgeModal] = useState(false)
  const [playConfetti, setPlayConfetti] = useState(false)

  const [remaining, setRemaining] = useState(initialRemaining)
  const [timerRunning] = useState(playAnimation)

  const leagueQuizFinished = useLeagueQuizFinished({
    quizId: leagueQuizInstance.leagueQuiz.id,
    limit: 3,
  })

  const navigate = useNavigate()

  const [startCareerPathQuizMutation, { loading }] =
    useStartCareerPathQuizMutation()

  const apolloClient = useApolloClient()

  useInterval(
    () => {
      setRemaining((remaining) => remaining - 100) // decrease by 10 every 10 milliseconds
    },
    remaining <= 0 || !timerRunning ? null : 100 // run every 10 milliseconds
  )

  useEffect(() => {
    if (playAnimation) {
      setIsExpandedAdActive(true)
    }
  }, [])

  useEffect(() => {
    if (remaining <= AD_CAMPAIGN_HEADER_COLLAPSE_WHEN_TIME_LEFT_MS) {
      setIsExpandedAdActive(false)
    }
  }, [remaining])

  useEffect(() => {
    if (newBadges.length > 0 && remaining <= 0 && !videoAdActive) {
      setPlayConfetti(true)
      setShowBadgeModal(true)
    }
  }, [newBadges, remaining, videoAdActive])

  useEffect(() => {
    if (playConfetti) {
      setTimeout(() => {
        setPlayConfetti(false)
      }, 5000)
    }
  }, [playConfetti])

  const latestStartableQuiz = data?.league?.latestStartableQuiz

  const entries = leagueQuizFinished.data?.leagueQuiz?.leaderboard ?? []
  const friendEntries =
    leagueQuizFinished.data?.leagueQuiz?.friendsLeaderboard ?? []
  const viewerLeaderboardEntry =
    friendEntries.find((entry) => entry.user.id === authUser?.uid) ||
    leagueQuizFinished.data?.leagueQuiz?.viewerLeaderboardEntry

  const hasFriendEntries = friendEntries.length > 0

  return (
    <Page showFcQuizLogo logoColor={colors.grey200}>
      <Flex
        column
        horizontal={'center'}
        vertical={'space-between'}
        css={css`
          height: 100%;
          padding-top: 16px;
          padding-bottom: 16px;
          margin: 0 auto;
          width: 100%;
          max-width: 500px;
        `}
      >
        {showBadgeModal && (
          <ModalTrigger
            forceIsOpen={showBadgeModal}
            button={() => (
              <div
                css={css`
                  position: absolute;
                  width: 0;
                  height: 0;
                `}
              />
            )}
            modal={({ closeModal }) => (
              <Modal
                heading={
                  newBadges.length > 1
                    ? t('New badges unlocked!')
                    : t('New badge unlocked!')
                }
                closeModal={closeModal}
                maxWidth={500}
                css={css`
                  text-align: center;
                `}
                transparent
              >
                <BadgeModalContent badges={newBadges} onClose={closeModal} />
              </Modal>
            )}
          />
        )}

        <Flex
          column
          horizontal={'center'}
          css={css`
            width: 100%;
            padding-top: 16px;
          `}
        >
          <CareerPathQuestionResult
            completedQuestion={lastCompletedQuestion}
            correct={leagueQuizInstance.result.correctAnswersCount > 0}
          />

          <CareerPath
            careerPath={lastCompletedQuestion.careerPath}
            showYear={true}
            lastCompleted={true}
          />

          <Spacer height={'medium'} />

          <GuessIndicator
            answers={lastCompletedQuestion.answers}
            activeQuestion={lastCompletedQuestion}
          />
        </Flex>
        {!videoAdActive && remaining <= 2000 && (
          <Flex
            column
            vertical={'center'}
            css={[
              margin.top('large'),
              css`
                opacity: 0;
                animation: ${playAnimation ? growAndFade : fadeIn} 350ms
                  cubic-bezier(0.4, 0, 0.15, 1);
                animation-delay: 600ms;
                animation-fill-mode: forwards;
                width: 100%;
              `,
            ]}
          >
            {!nextQuizLoading && !loading && latestStartableQuiz && (
              <PrimaryButton
                css={css`
                  border-radius: 99px;
                  padding: 5px 50px;
                  align-self: center;
                `}
                onClick={() => {
                  if (
                    latestStartableQuiz &&
                    latestStartableQuiz.quizType === 'careerPath'
                  ) {
                    startCareerPathQuizMutation({
                      variables: {
                        input: {
                          leagueQuizId: latestStartableQuiz?.id,
                        },
                      },
                    }).then((result) => {
                      const quizInstanceId = result.data?.startCareerPathQuiz.id
                      if (quizInstanceId) {
                        trackEvent(AnalyticsEventType.StartQuiz, {
                          quizId: leagueQuizInstance.leagueQuiz.id,
                          leagueId: leagueQuizInstance.leagueQuiz.league.id,
                          leagueSlug,
                          isCareerPath: true,
                        })
                        navigate(
                          `/play-quiz/${quizInstanceId}?quizType=${data.league?.latestStartableQuiz?.quizType}`
                        )
                      }
                    })
                  }
                }}
              >
                <TextNew condensed italic strong spacingEm={0} color={'white'}>
                  {t(`Play Next Quiz`)}
                </TextNew>
              </PrimaryButton>
            )}

            <Spacer height={16} />
            <SecondaryButton
              css={css`
                border-radius: 99px;
                padding: 5px 50px;
                align-self: center;
              `}
              onClick={() => {
                apolloClient
                  .refetchQueries({
                    include: [dailyCareerPathQuery, viewerQuery],
                  })
                  .then(() => navigate('/'))
              }}
            >
              <TextNew
                condensed
                italic
                strong
                spacingEm={0}
                color={colors.black}
              >
                {t(`Go to home page`)}
              </TextNew>
            </SecondaryButton>

            <Spacer height={16} />

            <MyPlacement item={viewerLeaderboardEntry} />
            <Spacer height={16} />
            <TopPlayers
              quizId={leagueQuizInstance.leagueQuiz.id}
              leagueSlug={leagueSlug}
              entries={hasFriendEntries ? friendEntries : entries}
              viewerHasFriends={hasFriendEntries}
              hideLink
            />
          </Flex>
        )}
        {playConfetti && (
          <Lottie
            animationData={confetti}
            loop={false}
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
              zIndex: 1000000000,
              // allow clicks to go through
              pointerEvents: 'none',
            }}
          />
        )}
      </Flex>
    </Page>
  )
}
