import { QuizType } from '../../views/Generate/GeneratePage'
import { Locale } from '../i18n/locale'
export type LocaleString = {
  en?: string
  no?: string
  es?: string
  de?: string
  fr?: string
  it?: string
  ar?: string
  pt?: string
  ja?: string
  sv?: string
  da?: string
}

export enum CampaignImageType {
  titleImage,
  titleImageMobile,
  countdownImage,
  countdownImageMobile,
  bannerImage,
  bannerImageMobile,
  expandedImage,
  expandedImageMobile,
}

export type SanityLeagueQuizRaw = {
  id: string
  title: LocaleString
  availableFrom: string
  availableTo?: string
  questionsCount: number
  questions: {
    id: string
    text: LocaleString
    alternatives: {
      id: string
      text: LocaleString
      correct: boolean
    }[]
  }[]
  randomizeQuestionOrder?: boolean
  embedPromotionalText?: LocaleString
  slug?: string
  imageUrl?: string
  league: {
    id: string
    title: LocaleString
    slug?: string
    imageUrl?: string
  }
}

export type SanityQuestion = {
  id: string
  text: string
  alternatives: {
    id: string
    text: string
    correct: boolean
  }[]
}

export type SanityLeagueQuiz = {
  id: string
  title: string
  availableFrom: string
  availableTo?: string
  questionsCount: number
  questions: SanityQuestion[]
  embedPromotionalText?: string
  randomizeQuestionOrder?: boolean
  imageUrl?: string
  league: {
    id: string
    title: string
    slug?: string
    imageUrl?: string
  }
  quizType: QuizType
}

export type SanityLeagueQuizCampaign = {
  id: string
  name: string
  campaignLink: string
  exclusiveLeagues?: { id: string }[]
  excludedLeagues?: { id: string }[]
  alwaysIncludedLeagues?: { id: string }[]
  languageRestrictions?: Locale[]
  authenticatedOnly?: boolean
  backgroundColor?: string
  backgroundImageUrl?: string
  titleScreenImageUrl?: string
  titleScreenImageMobileUrl?: string
  countdownImageUrl?: string
  countdownImageMobileUrl?: string
  partnerLogoUrl?: string
  bannerImageUrl?: string
  bannerImageMobileUrl?: string
  expandedImageUrl?: string
  expandedImageMobileUrl?: string
  finishedBannerImageUrl?: string
  finishedBannerImageMobileUrl?: string
}
