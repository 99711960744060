/** @jsxImportSource @emotion/react */

import { css, keyframes } from '@emotion/react'
import { isProduction } from '../../config/config'
import { AnalyticsEventType, trackEvent } from '../../lib/analytics'
import { getCurrentLocale, Locale } from '../../lib/i18n/locale'
import { SanityLeagueQuizCampaign } from '../../lib/sanity/types'
import {
  calculateScoreHeaderHeight,
  collapsedHeaderHeight,
  QuizState,
  useLeagueQuizContext,
} from '../../views/LeagueQuiz/LeagueQuizContext'
import { Flex } from '../layout/Flex'
import { AdSize, RevIqAdComponent } from '../reviq/RevIqAdComponent'
import { SanityCampaignHeader } from './CampaignHeader'
import { useDetectAdBlock } from './useDetectAdBlock'

const QuizAd = ({
  isEmbed = false,
  campaign,
  isChallenge = false,
  isDailyCareerPath = false,
  leagueId,
  quizId,
}: {
  isEmbed?: boolean
  campaign?: SanityLeagueQuizCampaign
  isChallenge?: boolean
  isDailyCareerPath?: boolean
  leagueId?: string
  quizId?: string
}) => {
  const adblockDetected = useDetectAdBlock({ skip: !!campaign })
  const { quizState, isExpandedAdActive } = useLeagueQuizContext()

  const locale = getCurrentLocale()

  const showReviqAds = () => {
    // Can only be shown in production
    if (!isProduction) {
      return false
    }

    // Does not work in embed mode
    if (isEmbed) {
      return false
    }

    // Only show if there is no campaign from sanity
    if (campaign) {
      return false
    }

    // Dont´t show for Norway
    if (locale === Locale.no) {
      return false
    }

    return true
  }

  const canShowAd = showReviqAds()

  const expand = keyframes`
      from {
        height: 33vh;
      }
      to {
        height: ${calculateScoreHeaderHeight};
      }
    `

  const expandCountdown = keyframes`
        from {
            height: ${collapsedHeaderHeight};
        }
        to {
            height: 33vh;
        }
    `

  const collapse = keyframes`
        from {
          height: ${calculateScoreHeaderHeight};
        }
        to {
            height: ${collapsedHeaderHeight};
        }
    `

  if (quizState === QuizState.START_SCREEN) {
    return null
  }

  if (campaign) {
    return (
      <Flex
        horizontal={'center'}
        css={css`
          width: 100%;
          height: ${quizState === QuizState.COUNTDOWN
            ? '33vh'
            : isExpandedAdActive
            ? calculateScoreHeaderHeight
            : collapsedHeaderHeight};

          ${quizState != QuizState.COUNTDOWN
            ? isExpandedAdActive && campaign?.expandedImageUrl
              ? css`
                  animation: ${expand} 350ms cubic-bezier(0.4, 0, 0.15, 1)
                    forwards;
                `
              : !isExpandedAdActive &&
                css`
                  animation: ${collapse} 350ms cubic-bezier(0.4, 0, 0.15, 1)
                    forwards;
                `
            : campaign.countdownImageUrl &&
              css`
                animation: ${expandCountdown} 350ms
                  cubic-bezier(0.4, 0, 0.15, 1) forwards;
              `}
        `}
      >
        <SanityCampaignHeader
          campaign={campaign}
          isEmbed={isEmbed}
          isChallenge={isChallenge}
          isDailyCareerPath={isDailyCareerPath}
        />
      </Flex>
    )
  }

  if (canShowAd) {
    return (
      <Flex
        horizontal="center"
        vertical="center"
        css={css`
          height: ${isExpandedAdActive ? calculateScoreHeaderHeight : '0px'};
          overflow: hidden;
          ${isExpandedAdActive
            ? css`
                animation: ${expand} 350ms cubic-bezier(0.4, 0, 0.15, 1)
                  forwards;
              `
            : css`
                animation: ${collapse} 350ms cubic-bezier(0.4, 0, 0.15, 1)
                  forwards;
              `}
        `}
      >
        <RevIqAdComponent
          adSize={AdSize.MEDIUM_RECTANGLE}
          visible={isExpandedAdActive}
          trackEvent={() => {
            trackEvent(AnalyticsEventType.RevIqAdImpression, {
              leagueId,
              adSize: AdSize.MEDIUM_RECTANGLE,
              quizId,
              isEmbed,
              adblockDetected,
            })
          }}
        />
      </Flex>
    )
  }

  return null
}

export default QuizAd
