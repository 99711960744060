import { gql, useMutation, useQuery } from '@apollo/client'
import { ChallengeLeagueMinimal } from '../Challenge/queries'
import {
  LeaderboardItem,
  leagueLeaderboardItemFragment,
} from '../League/queries'
import {
  ActiveQuestion,
  activeQuestionFragment,
  AnswerQuestionInput,
  CompletedQuestion,
  completedQuestionFragment,
  LeagueQuiz,
  LeagueQuizInstanceResult,
  LeagueQuizInstanceStatus,
} from '../LeagueQuiz/queries'

export type HostLiveQuizInput = {
  quizId: string
  startAt?: Date
  leagueId?: string
  locale?: string
}

export type HostInstanceStatus = 'pending' | 'active' | 'finished' | 'completed'

export type LiveQuizHostInstance = {
  id: string
  hostId: string
  locale: string
  leagueId?: string
  quiz?: {
    id: string
    title: string
  }
  status: HostInstanceStatus
  invitationCode: string
  questionsCount: number
  participantCount: number
  readableQuestion?: LiveActiveQuestion
  activeQuestion?: LiveActiveQuestion
  completedQuestions: LiveCompletedQuestion[]
  league?: ChallengeLeagueMinimal
}

export type LiveActiveQuestion = {
  id: string
  text: string
  imageUrl?: string
  alternatives: {
    id: string
    text: string
  }[]
  maxTimeToAnswerInSeconds: number
  numberOfAnswers: number
  secondsLeft: number
  correctAlternativeIds?: string[]
}

export type LiveCompletedQuestion = {
  id: string
  text: string
  imageUrl?: string
  alternatives: {
    id: string
    text: string
    isCorrectAlternative: boolean
    numberOfAnswers: number
  }[]
}

export type LiveQuizDisplayInstance = {
  id: string
  locale: string
  status: HostInstanceStatus
  invitationCode: string
  leagueId?: String
  league?: ChallengeLeagueMinimal
  startTime: string
  questionsCount: number
  readableQuestion?: LiveActiveQuestion
  activeQuestion?: LiveActiveQuestion
  completedQuestions?: LiveCompletedQuestion[]
  participants: {
    id: string
    username: string
    displayName: string
    avatarData: string
  }[]
  participantCount: number
  leaderboardCurrentQuestion?: LeaderboardItem[]
  leaderboard: LeaderboardItem[]
}

export type LiveQuizInstance = {
  id: string
  status: LeagueQuizInstanceStatus
  liveHostInstanceStatus?: HostInstanceStatus
  leagueQuiz: LeagueQuiz
  liveHostInstanceId?: string
  completedQuestionsLive: CompletedQuestion[]
  activeQuestion: ActiveQuestion | null
  activeQuestionLive: ActiveQuestion | null
  readableQuestionLive: ActiveQuestion | null
  result?: LeagueQuizInstanceResult
  leaderboardParticipantCount: number | null
  liveHostLeague?: ChallengeLeagueMinimal | null
}

/// ============== Host Live Quiz ==============

const activeQuestionLiveQuizFragment = gql`
  fragment ActiveQuestionLiveQuizFragment on LiveActiveQuestion {
    id
    text
    imageUrl
    maxTimeToAnswerInSeconds
    alternatives {
      id
      text
    }
    numberOfAnswers
    secondsLeft
    correctAlternativeIds
  }
`

const completedQuestionLiveQuizFragment = gql`
  fragment CompletedQuestionLiveQuizFragment on LiveCompletedQuestion {
    id
    text
    imageUrl
    points
    alternatives {
      id
      text
      isCorrectAlternative
      numberOfAnswers
    }
  }
`

const hostLiveQuizFragment = gql`
  fragment HostLiveQuizFragment on LiveQuizHostInstance {
    id
    hostId
    locale
    status
    leagueId
    quiz {
      id
      title
    }
    invitationCode
    participantCount
    questionsCount
    activeQuestion {
      ...ActiveQuestionLiveQuizFragment
    }
    readableQuestion {
      ...ActiveQuestionLiveQuizFragment
    }
    completedQuestions {
      ...CompletedQuestionLiveQuizFragment
    }
    league {
      id
      slug
      title
      description
      iconImageUrl
      textColor
      backgroundColor
      backgroundImageUrl
    }
  }
  ${activeQuestionLiveQuizFragment}
  ${completedQuestionLiveQuizFragment}
`
const hostLiveQuizNoParticipantsFragment = gql`
  fragment HostLiveQuizNoParticipantsFragment on LiveQuizHostInstance {
    id
    hostId
    locale
    status
    leagueId
    quiz {
      id
      title
    }
    invitationCode
    questionsCount
    activeQuestion {
      ...ActiveQuestionLiveQuizFragment
    }
    readableQuestion {
      ...ActiveQuestionLiveQuizFragment
    }
    completedQuestions {
      ...CompletedQuestionLiveQuizFragment
    }
    league {
      id
      slug
      title
      description
      iconImageUrl
      textColor
      backgroundColor
      backgroundImageUrl
    }
  }
  ${activeQuestionLiveQuizFragment}
  ${completedQuestionLiveQuizFragment}
`

export const getLiveQuizHostInstanceQuery = gql`
  query getLiveQuizHostInstance($id: ID!) {
    getLiveQuizHostInstance(id: $id) {
      ...HostLiveQuizNoParticipantsFragment
    }
  }
  ${hostLiveQuizNoParticipantsFragment}
`

export const useGetLiveQuizHostInstanceQuery = (id: string) => {
  return useQuery<{ getLiveQuizHostInstance: LiveQuizHostInstance }>(
    getLiveQuizHostInstanceQuery,
    { variables: { id }, fetchPolicy: 'cache-and-network', pollInterval: 2000 }
  )
}

const hostLiveQuizMutation = gql`
  mutation hostLiveQuiz($input: HostLiveQuizInput!) {
    hostLiveQuiz(input: $input) {
      id
    }
  }
`

export const useHostLiveQuizMutation = () => {
  return useMutation<
    {
      hostLiveQuiz: LiveQuizHostInstance
    },
    { input: HostLiveQuizInput }
  >(hostLiveQuizMutation)
}

export type HostActionInput = {
  instanceId: string
  currentQuestionId?: string
}

export const startLiveQuizMutation = gql`
  mutation startLiveQuiz($input: HostActionInput!) {
    startLiveQuiz(input: $input) {
      id
      status
      activeQuestion {
        ...ActiveQuestionLiveQuizFragment
      }
      readableQuestion {
        ...ActiveQuestionLiveQuizFragment
      }
      completedQuestions {
        ...CompletedQuestionLiveQuizFragment
      }
    }
  }
  ${activeQuestionLiveQuizFragment}
  ${completedQuestionLiveQuizFragment}
`

export const useStartLiveQuizMutation = () => {
  return useMutation<
    {
      startLiveQuiz: LiveQuizHostInstance
    },
    { input: HostActionInput }
  >(startLiveQuizMutation)
}

export const hostSetQuestionReadableMutation = gql`
  mutation hostSetQuestionReadable($input: HostActionInput!) {
    hostSetQuestionReadable(input: $input) {
      id
      status
      activeQuestion {
        ...ActiveQuestionLiveQuizFragment
      }
      readableQuestion {
        ...ActiveQuestionLiveQuizFragment
      }
      completedQuestions {
        ...CompletedQuestionLiveQuizFragment
      }
    }
  }
  ${activeQuestionLiveQuizFragment}
  ${completedQuestionLiveQuizFragment}
`

export const useHostSetQuestionReadableMutation = () => {
  return useMutation<
    {
      hostSetQuestionReadable: LiveQuizHostInstance
    },
    { input: HostActionInput }
  >(hostSetQuestionReadableMutation)
}

export const hostActivateQuestionMutation = gql`
  mutation hostActivateQuestion($input: HostActionInput!) {
    hostActivateQuestion(input: $input) {
      id
      status
      activeQuestion {
        ...ActiveQuestionLiveQuizFragment
      }
      readableQuestion {
        ...ActiveQuestionLiveQuizFragment
      }
      completedQuestions {
        ...CompletedQuestionLiveQuizFragment
      }
    }
  }
  ${activeQuestionLiveQuizFragment}
  ${completedQuestionLiveQuizFragment}
`

export const useHostActivateQuestionMutation = () => {
  return useMutation<
    {
      hostActivateQuestion: LiveQuizHostInstance
    },
    { input: HostActionInput }
  >(hostActivateQuestionMutation)
}

export const hostCloseQuestionMutation = gql`
  mutation hostCloseQuestion($input: HostActionInput!) {
    hostCloseQuestion(input: $input) {
      id
      status
      activeQuestion {
        ...ActiveQuestionLiveQuizFragment
      }
      readableQuestion {
        ...ActiveQuestionLiveQuizFragment
      }
      completedQuestions {
        ...CompletedQuestionLiveQuizFragment
      }
    }
  }
  ${activeQuestionLiveQuizFragment}
  ${completedQuestionLiveQuizFragment}
`

export const useHostCloseQuestionMutation = () => {
  return useMutation<
    {
      hostCloseQuestion: LiveQuizHostInstance
    },
    { input: HostActionInput }
  >(hostCloseQuestionMutation)
}

export const getLiveQuizHostInstancesQuery = gql`
  query getLiveQuizHostInstances {
    getLiveQuizHostInstances {
      ...HostLiveQuizFragment
    }
  }

  ${hostLiveQuizFragment}
`

export const useGetLiveQuizHostInstancesQuery = () => {
  return useQuery<{ getLiveQuizHostInstances: LiveQuizHostInstance[] }>(
    getLiveQuizHostInstancesQuery,
    { fetchPolicy: 'cache-and-network' }
  )
}

export const hostCompleteQuizMutation = gql`
  mutation hostCompleteQuiz($input: HostActionInput!) {
    hostCompleteQuiz(input: $input) {
      id
      status
      activeQuestion {
        ...ActiveQuestionLiveQuizFragment
      }
      readableQuestion {
        ...ActiveQuestionLiveQuizFragment
      }
      completedQuestions {
        ...CompletedQuestionLiveQuizFragment
      }
    }
  }
  ${activeQuestionLiveQuizFragment}
  ${completedQuestionLiveQuizFragment}
`

export const useHostCompleteQuizMutation = () => {
  return useMutation<
    {
      hostCompleteQuiz: LiveQuizHostInstance
    },
    { input: HostActionInput }
  >(hostCompleteQuizMutation)
}

// ================== Player ==================

export type JoinLiveQuizInput = {
  invitationCode: string
}

export const joinLiveQuizMutation = gql`
  mutation joinLiveQuiz($input: JoinLiveQuizInput!) {
    joinLiveQuiz(input: $input) {
      id
    }
  }
`

export const useJoinLiveQuizMutation = () => {
  return useMutation<
    {
      joinLiveQuiz: { id: string }
    },
    { input: JoinLiveQuizInput }
  >(joinLiveQuizMutation)
}

const getLivePlayQuizInstanceQuery = gql`
  query getLivePlayQuizInstance($id: ID!) {
    getLivePlayQuizInstance(id: $id) {
      id
      status
      liveHostInstanceId
      liveHostInstanceStatus
      leagueQuiz {
        id
        title
        slug
        viewerCanStartQuiz
        questionsCount
        league {
          id
          slug
          title
          frequency
        }
      }
      completedQuestionsLive {
        id
        text
        imageUrl
        secondsLeft
        points
        alternatives {
          id
          isCorrectAlternative
          selectedAlternative
          text
          numberOfAnswers
        }
      }
      activeQuestionLive {
        ...ActiveQuestionFragment
      }
      readableQuestionLive {
        ...ActiveQuestionFragment
      }
      liveHostLeague {
        id
        slug
        title
        description
        iconImageUrl
        textColor
        backgroundColor
        backgroundImageUrl
      }
    }
  }

  ${activeQuestionFragment}
`

export const useGetLivePlayQuizInstanceQuery = (id: string) => {
  return useQuery<{ getLivePlayQuizInstance?: LiveQuizInstance }>(
    getLivePlayQuizInstanceQuery,
    {
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }
  )
}

const playerAnswerQuestionMutation = gql`
  mutation playerAnswerQuestion($input: AnswerQuestionInput!) {
    playerAnswerQuestion(input: $input) {
      id
      status
      liveHostInstanceStatus
      activeQuestionLive {
        ...ActiveQuestionFragment
      }
      readableQuestionLive {
        ...ActiveQuestionFragment
      }
      completedQuestionsLive {
        ...CompletedQuestionFragment
      }
      result {
        correctAnswersCount
        correctAnswerScore
        timeBonus
        totalScore
      }
    }
  }
  ${activeQuestionFragment}
  ${completedQuestionFragment}
`

export const usePlayerAnswerQuestionMutation = () => {
  return useMutation<
    {
      playerAnswerQuestion: LiveQuizInstance
    },
    { input: AnswerQuestionInput }
  >(playerAnswerQuestionMutation)
}

const userResultQuery = gql`
  query getLivePlayQuizInstance($id: ID!) {
    getLivePlayQuizInstance(id: $id) {
      id
      result {
        correctAnswersCount
        correctAnswerScore
        timeBonus
        totalScore
      }
    }
  }
`

export const useLiveUserResultQuery = (id: string) => {
  return useQuery<{
    getLivePlayQuizInstance: {
      id: string
      result: {
        correctAnswersCount: number
        correctAnswerScore: number
        timeBonus: number
        totalScore: number
      }
    }
  }>(userResultQuery, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  })
}

// ================ Display page ====================

export const displayLiveQuizFragment = gql`
  fragment DisplayLiveQuizFragment on LiveQuizDisplayInstance {
    id
    invitationCode
    leagueId
    startTime
    locale
    league {
      id
      slug
      title
      description
      iconImageUrl
      textColor
      backgroundColor
      backgroundImageUrl
      locale
    }
    status
    questionsCount
    activeQuestion {
      ...ActiveQuestionLiveQuizFragment
    }
    readableQuestion {
      ...ActiveQuestionLiveQuizFragment
    }
    completedQuestions {
      ...CompletedQuestionLiveQuizFragment
    }
    participants {
      id
      username
      displayName
      avatarData
    }
    participantCount
    leaderboardCurrentQuestion {
      ...LeagueLeaderboardItem
    }
    leaderboard(limit: 10) {
      ...LeagueLeaderboardItem
    }
  }
  ${activeQuestionLiveQuizFragment}
  ${completedQuestionLiveQuizFragment}
  ${leagueLeaderboardItemFragment}
`
export const displayLiveQuizNoParticipantsFragment = gql`
  fragment DisplayLiveQuizNoParticipantsFragment on LiveQuizDisplayInstance {
    id
    invitationCode
    leagueId
    startTime
    locale
    league {
      id
      slug
      title
      description
      iconImageUrl
      textColor
      backgroundColor
      backgroundImageUrl
      locale
    }
    status
    questionsCount
    activeQuestion {
      ...ActiveQuestionLiveQuizFragment
    }
    readableQuestion {
      ...ActiveQuestionLiveQuizFragment
    }
    completedQuestions {
      ...CompletedQuestionLiveQuizFragment
    }
    leaderboardCurrentQuestion {
      ...LeagueLeaderboardItem
    }
    leaderboard(limit: 10) {
      ...LeagueLeaderboardItem
    }
  }
  ${activeQuestionLiveQuizFragment}
  ${completedQuestionLiveQuizFragment}
  ${leagueLeaderboardItemFragment}
`

export const getLiveQuizDisplayInstanceQuery = gql`
  query getLiveQuizDisplayInstance($id: ID!) {
    getLiveQuizDisplayInstance(id: $id) {
      ...DisplayLiveQuizFragment
    }
  }
  ${displayLiveQuizFragment}
`

export const useGetLiveQuizDisplayInstanceQuery = (id: string) => {
  return useQuery<{ getLiveQuizDisplayInstance: LiveQuizDisplayInstance }>(
    getLiveQuizDisplayInstanceQuery,
    { variables: { id }, fetchPolicy: 'cache-and-network' }
  )
}

export const getLiveQuizDisplayInstanceForLeagueQuery = gql`
  query getLiveQuizDisplayInstanceForLeague($leagueId: ID!) {
    getLiveQuizDisplayInstanceForLeague(leagueId: $leagueId) {
      ...DisplayLiveQuizNoParticipantsFragment
    }
  }
  ${displayLiveQuizNoParticipantsFragment}
`

export const useGetLiveQuizDisplayInstanceForLeagueQuery = (
  leagueId: string
) => {
  return useQuery<{
    getLiveQuizDisplayInstanceForLeague: LiveQuizDisplayInstance
  }>(getLiveQuizDisplayInstanceForLeagueQuery, {
    variables: { leagueId },
    fetchPolicy: 'cache-and-network',
  })
}

export const getLiveQuizDisplayInstanceByCodeQuery = gql`
  query getLiveQuizDisplayInstanceByCode($code: String!) {
    getLiveQuizDisplayInstanceByCode(code: $code) {
      ...DisplayLiveQuizFragment
    }
  }
  ${displayLiveQuizFragment}
`

export const useGetLiveQuizDisplayInstanceByCodeQuery = (code: string) => {
  return useQuery<{
    getLiveQuizDisplayInstanceByCode: LiveQuizDisplayInstance
  }>(getLiveQuizDisplayInstanceByCodeQuery, {
    variables: { code },
    fetchPolicy: 'cache-and-network',
  })
}

export const getLeaderboardForLiveQuizInstanceQuery = gql`
  query getLeaderboardForLiveQuizInstance($id: ID!) {
    getLeaderboardForLiveQuizInstance(id: $id) {
      id
      invitationCode
      leagueId
      status

      leaderboard(limit: 10) {
        ...LeagueLeaderboardItem
      }
    }
  }
  ${leagueLeaderboardItemFragment}
`

export const useGetLeaderboardForLiveQuizInstanceQuery = (id: string) => {
  return useQuery<{
    getLeaderboardForLiveQuizInstance: {
      id: string
      invitationCode: string
      leagueId: string
      status: HostInstanceStatus
      leaderboard: LeaderboardItem[]
    }
  }>(getLeaderboardForLiveQuizInstanceQuery, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  })
}

//completeLiveQuiz(input: HostActionInput!): LiveQuizHostInstance

export const useCompleteLiveQuizMutation = () => {
  return useMutation<{}, { input: HostActionInput }>(
    gql`
      mutation completeLiveQuiz($input: HostActionInput!) {
        completeLiveQuiz(input: $input) {
          id
          status
        }
      }
    `
  )
}
