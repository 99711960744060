import { gql, useMutation, useQuery } from '@apollo/client'
import { Club, clubFragment, ClubRole } from '../Clubs/queries'
import { Badge, TrophyType } from '../League/queries'
import { badgeFragment } from '../LeagueQuiz/queries'

export type ProfileTrophy = {
  id: string
  type: TrophyType
  name: string
  league: {
    id: string
    title: string
  }
  club?: Club
}

export type UserStats = {
  id: string
  score: number
  quizzesPlayed: number
  uniqueLeagues: number
  textQuizzes: number
  careerPathQuizzes: number
  firstQuizDate?: Date
  lastQuizDate?: Date
}

export type User = {
  id: string
  username: string
  slug: string
  trophies: ProfileTrophy[]
  avatarData?: string
  coins: number
  unlockedStyles: string[]
  clubMemberships: {
    id: string
    role: ClubRole
    club: Club
  }[]
  mainClubMembership?: {
    id: string
    role: ClubRole
    club: Club
  }
  displayName?: string
  badges: Badge[]
  friends: FriendUser[]
  friendRequests: FriendUser[]
  friendStatus: FriendStatus
  stats?: UserStats
}

export type FriendUser = {
  id: string
  username: string
  slug: string
  avatarData?: string
  displayName: string
  friendStatus: FriendStatus
}

export enum FriendStatus {
  viewer = 'viewer',
  friend = 'friend',
  notFriend = 'notFriend',
  requestPending = 'requestPending',
}

export type ExtendedChallengeInstance = {
  id: string
  leagueTitle?: string
  quizTitle?: string
  originalInstanceId: string
  originalUserId: string
  originalUsername?: string
  originalProfilePictureUri?: string
  originalUserScore: number
  challengerInstanceId: string
  challengerUserId: string
  challengerUsername?: string
  challengerProfilePictureUri?: string
  challengerUserScore: number
  createdAt: Date
}

export const friendFragment = gql`
  fragment Friend on User {
    id
    username
    slug
    avatarData
    displayName
    friendStatus
  }
`

export const userProfileQuery = gql`
  query userProfile($slug: String!) {
    user(slug: $slug) {
      id
      username
      slug
      avatarData
      coins
      unlockedStyles
      displayName
      mainClubMembership {
        id
        role
        club {
          ...Club
        }
      }
      badges {
        ...BadgeFragment
      }
      friends {
        ...Friend
      }
      friendRequests {
        ...Friend
      }
      friendStatus
      stats {
        id
        score
        quizzesPlayed
        uniqueLeagues
        textQuizzes
        careerPathQuizzes
        firstQuizDate
        lastQuizDate
      }
    }
  }
  ${clubFragment}
  ${badgeFragment}
  ${friendFragment}
`

export const userProfileAvatarQuery = gql`
  query userProfile($slug: String!) {
    user(slug: $slug) {
      id
      username
      slug
      avatarData
      coins
      unlockedStyles
      displayName
    }
  }
`

export const useUserProfileQuery = (userSlug: string, skip?: boolean) => {
  return useQuery<{ user: User | null }>(userProfileQuery, {
    variables: { slug: userSlug, skip },
  })
}

export const useUserProfileAvatarQuery = (userSlug: string, skip?: boolean) => {
  return useQuery<{ user: User }>(userProfileAvatarQuery, {
    variables: { slug: userSlug, skip },
  })
}

export const userClubMembershipsQuery = gql`
  query userClubMemberships($slug: String!) {
    user(slug: $slug) {
      id
      clubMemberships {
        id
        role
        club {
          ...Club
        }
      }
    }
  }
  ${clubFragment}
`

export const useUserClubMembershipsQuery = (
  userSlug: string,
  skip?: boolean
) => {
  const query = useQuery<{
    user: {
      id: string
      clubMemberships: {
        id: string
        role: ClubRole
        club: Club
      }[]
    } | null
  }>(userClubMembershipsQuery, {
    variables: { slug: userSlug },
    skip: skip,
  })

  return {
    ...query,
    data: query.data?.user?.clubMemberships ?? [],
  }
}

export const useSetMainClubMutation = () => {
  return useMutation<unknown, { clubId: string }>(gql`
    mutation setMainClub($clubId: ID!) {
      setMainClub(clubId: $clubId) {
        id
        clubMemberships {
          id
          role
          club {
            ...Club
          }
        }
      }
    }

    ${clubFragment}
  `)
}

export const useChallengesListForViewer = () => {
  return useQuery<{
    challengesListForViewer: ExtendedChallengeInstance[]
  }>(gql`
    query extendedChallengeInstances {
      challengesListForViewer {
        id
        leagueTitle
        quizTitle
        originalInstanceId
        originalUserId
        originalUsername
        originalProfilePictureUri
        originalUserScore
        challengerInstanceId
        challengerUserId
        challengerUsername
        challengerProfilePictureUri
        challengerUserScore
        createdAt
      }
    }
  `)
}

export const useUserDataDeletionRequestMutation = () => {
  return useMutation<
    { requestDeleteUserData: boolean },
    {
      input: {
        onlyDeleteData: boolean
      }
    }
  >(
    gql`
      mutation userDataDeletionRequest($input: DeleteUserDataInput!) {
        requestDeleteUserData(input: $input)
      }
    `
  )
}

export const useBadgeSetSeenByUserMutation = () => {
  return useMutation<{ setBadgeSeenByUser: Badge }, { badgeId: string }>(gql`
    mutation setBadgeSeenByUser($badgeId: ID!) {
      setBadgeSeenByUser(badgeId: $badgeId) {
        ...BadgeFragment
      }
    }

    ${badgeFragment}
  `)
}

//    randomAvatarData: String!
export const useRandomAvatarDataQuery = () => {
  return useQuery<{ randomAvatarData: string }>(
    gql`
      query randomAvatarData {
        randomAvatarData
      }
    `
  )
}

export type FriendRequestInput = {
  userId: string
}

export const useAddFriendMutation = () => {
  return useMutation<{ addFriend: User }, { input: FriendRequestInput }>(
    gql`
      mutation addFriend($input: FriendRequestInput!) {
        addFriend(input: $input) {
          id
          username
          slug
          avatarData
          displayName
          friendStatus
        }
      }
    `
  )
}

export const useAcceptFriendRequestMutation = () => {
  return useMutation<
    { acceptFriendRequest: User },
    { input: FriendRequestInput }
  >(
    gql`
      mutation acceptFriendRequest($input: FriendRequestInput!) {
        acceptFriendRequest(input: $input) {
          id
          username
          slug
          avatarData
          displayName
          friendStatus
        }
      }
    `
  )
}

export const useRejectFriendRequestMutation = () => {
  return useMutation<
    { rejectFriendRequest: User },
    { input: FriendRequestInput }
  >(
    gql`
      mutation rejectFriendRequest($input: FriendRequestInput!) {
        rejectFriendRequest(input: $input) {
          id
          username
          slug
          avatarData
          displayName
          friendStatus
        }
      }
    `
  )
}

export const useRemoveFriendMutation = () => {
  return useMutation<{ removeFriend: User }, { input: FriendRequestInput }>(
    gql`
      mutation removeFriend($input: FriendRequestInput!) {
        removeFriend(input: $input) {
          id
          username
          slug
          avatarData
          displayName
          friendStatus
        }
      }
    `
  )
}
